import React from "react";
import { Link } from "gatsby";
import Imgix from "react-imgix";
import cuid from "cuid";
import { format } from "date-fns";
import styles from "./RelatedArticles.module.scss";

export const Article = ({ article }) => {
  const { title, metadata, modified_at } = article;
  const { parent_page, url_path } = metadata;
  let url = "";
  if (parent_page) {
    url += `/${parent_page.metadata.url_path}`;
  }
  url += `/${url_path}`;
  return (
    <Link to={`${url}`} className={styles.blogItem}>
      {metadata && metadata.image && metadata.image.imgix_url && (
        <Imgix
          src={`${metadata.image.imgix_url}?auto=format`}
          height={212}
          width={327}
          htmlAttributes={{
            alt: "Bidddy 101 - Your Guide To Getting Started On Bidddy",
            src: `${metadata.image.imgix_url}?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
      )}
      <div>
        <h4>{title}</h4>
        <p>{format(new Date(modified_at), "MMM dd yyyy")}</p>
      </div>
    </Link>
  );
};

const RelatedArticles = ({ articles }) => (
  <div className={styles.relatedCards}>
    {articles.map((article) => (
      <Article article={article} key={`related-${cuid()}`} />
    ))}
  </div>
);

export default RelatedArticles;
