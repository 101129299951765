import React from "react";
import {
  FacebookIcon,
  LinkedInIcon,
  TwitterIcon,
  ShareIcon,
} from "../../Icons/SocialIcons";
import styles from "./SocialLinks.module.scss";

const SocialLinks = ({ shareURL, shareText, text }) => (
  <div className={styles.socialLinksContainer}>
    <div className={styles.text}>
      <p>{text || ""}</p>
    </div>
    <div className={styles.socialLinks}>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
          shareURL
        )}&t=${encodeURI(shareText)}`}
        target="_blank"
        title="Share on Facebook"
        rel="noreferrer"
      >
        <FacebookIcon />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite?url=${encodeURI(
          shareURL
        )}`}
        target="_blank"
        title="Share on LinkedIn"
        rel="noreferrer"
      >
        <LinkedInIcon />
      </a>
      <a
        href={`https://twitter.com/share?url=${encodeURI(
          shareURL
        )}&text=${encodeURI(shareText)}`}
        target="_blank"
        title="Share on Twitter"
        rel="noreferrer"
      >
        <TwitterIcon />
      </a>
      <a
        title="Share via Email"
        href={`mailto:?subject=Check out this article&body=${shareText} ${shareURL}`}
      >
        <ShareIcon />
      </a>
    </div>
  </div>
);

export default SocialLinks;
