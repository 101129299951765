import React, { useState } from "react";
import axios from "axios";
import RoundedButton from "../../Buttons/RoundedButton";
import DefaultInput from "../../Inputs/DefaultInput";
import { encode, sendToast } from "../../../utils/helpers";
import styles from "./HaveAQuestion.module.scss";

const FORM_NAME = "FAQ__HaveAQuestion";

const HaveAQuestion = () => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    if (message !== "") {
      try {
        await axios.post(
          `/?${encode({
            "form-name": FORM_NAME,
            email: email || "",
            message,
          })}`,
          null,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setMessage("");
        setEmail("");
        sendToast(
          "Thank you for your submission! We will get back to you as soon as we can.",
          { type: "success" }
        );
      } catch (err) {
        sendToast(
          "Sorry there was an error submitting your message. Please try again or contact us at hello@bidddy.com.",
          { type: "error" }
        );
      }
    } else {
      sendToast("Please fill out your message before submitting", {
        type: "warning",
      });
    }
  };

  return (
    <div className={styles.haveAQuestionContainer} id="have-a-question">
      <h3>HAVE A QUESTION?</h3>
      <p>
        If you didn&apos;t find an answer to your question or need help with
        anything else, please send us your question and we&apos;ll add it to the
        list of FAQ&apos;s. Include your email and we&apos;ll send the answer
        directly to you.
      </p>
      <form
        className={styles.form}
        name={FORM_NAME}
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value={FORM_NAME} />
        <textarea
          name="message"
          className={styles.defaultTextArea}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type Your Question?"
        />
        <DefaultInput
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          type="email"
        />
        <RoundedButton type="submit" onClick={submitForm}>
          Submit
        </RoundedButton>
      </form>
    </div>
  );
};

export default HaveAQuestion;
