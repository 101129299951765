import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import styles from "./Newsletter.module.scss";
import { sendToast, encode } from "../../../utils/helpers";
import RoundedButton from "../../Buttons/RoundedButton";
import DefaultInput from "../../Inputs/DefaultInput";

const FORM_NAME = "BLOGHOME__JoinMailingList";

const Newsletter = () => {
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const joinedWaitlist = Cookies.get("joinedWaitlist");
    if (joinedWaitlist) {
      setSuccess(true);
    }
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (email !== "") {
      try {
        await axios.post(
          `/?${encode({ "form-name": FORM_NAME, email })}`,
          null,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        Cookies.set("joinedWaitlist", true);
        setSuccess(true);
        sendToast(
          "Thank you for joining our waitlist! We will inform you when you will be ready to use Bidddy.",
          { type: "success" }
        );
      } catch (err) {
        sendToast(
          "Sorry there was an error submitting your message. Please try again or contact us at hello@bidddy.com.",
          { type: "error" }
        );
      }
    }
  };

  return (
    <>
      {!success && (
        <form
          className={styles.joinNewsletter}
          name={FORM_NAME}
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <p>
            Join our email newsletter for the latest updates, tips and more from
            Bidddy.
          </p>
          <DefaultInput
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // className={styles.defaultInput}
            type="email"
            placeholder="Email"
            name="email"
          />
          <RoundedButton onClick={handleFormSubmit}>Submit</RoundedButton>
        </form>
      )}
      {success && (
        <div className="w-full mt-8">
          <p className="text-center">You have joined our waitlist!</p>
        </div>
      )}
    </>
  );
};

export default Newsletter;
