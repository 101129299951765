import React from "react";
import styles from "./TitleBlock.module.scss";

const TitleBlock = ({ title, id, onClick = null }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={handleClick}
      className={styles.titleBlock}
      id={id}
      onClick={handleClick}
    >
      <h2>{title}</h2>
      <p className={styles.backToTop}>Back To Top</p>
    </div>
  );
};

export default TitleBlock;
