import React from "react";
import styles from "../styles/NewBlog.module.scss";
import SEO from "./SEO/seo";
import useInlineParsingV2 from "../utils/inline-parsing.utils";
import Header from "../components/Navigation/Header";
import Footer from "../components/Home/internal/Footer";
// import NewFooter from "../components/Navigation/Footer/NewFooter";

const ContentPageTemplate = ({ pageContext }) => {
  const { metaContext, content, articles } = pageContext;
  const mountedContent = useInlineParsingV2(
    content,
    metaContext.canonical,
    metaContext.description,
    articles
  );
  console.log(metaContext.canonical);
  // Hack to fix the crazy container
  let mainContainerStyles = styles.blogContainer;
  let blogContainerStyles = styles.newBlogContainer;
  if (metaContext.canonical === "https://bidddy.com/learn/") {
    mainContainerStyles = styles.blogContainerLearn;
    blogContainerStyles = styles.newBlogContainerLearn;
  }
  // Pass into layout meta: title, description, canonical, image, structured_data
  return (
    <>
      <main className={mainContainerStyles}>
        <SEO metaContext={metaContext} />
        <Header />
        <div className={blogContainerStyles}>
          {content &&
            mountedContent &&
            mountedContent.map((articleContent, index) => {
              switch (articleContent.type) {
                case "html":
                  return (
                    <div
                      key={`${articleContent.type}-${index}`}
                      id={`${articleContent.type}-${index}`}
                      className={styles.blogContentBlock}
                      dangerouslySetInnerHTML={{
                        __html: articleContent.content,
                      }}
                    />
                  );
                case "jsx":
                  return articleContent.content;
                default:
                  return "";
              }
            })}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ContentPageTemplate;
