import React from 'react';
import styles from './CTA.module.scss';

const image = "https://imgix.cosmicjs.com/5174ba70-9c29-11ed-93ee-cb9a2cd68754-character.png?auto=format";

const CTA = ({
  title,
  description,
  button,
  buttonPath
}) => {

  return (
    <div className={styles.ctaContainer} style={{
      backgroundImage: `url(${image})`,
      backgroundSize: '25%',
      backgroundRepeat: "no-repeat",
      backgroundPosition: "left 100%",
    }}>
      <h3>{title}</h3>
      <p>{description}</p>
      <a href={buttonPath}>{button}</a>
    </div>
  )
};

export default CTA;