/* eslint-disable no-case-declarations */
import React from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import Dropdown from "../components/Dropdown/BidddyDropdown";
import TitleBlock from "../components/Blog/TitleBlock/TitleBlock";
import SocialLinks from "../components/Blog/SocialLinks/SocialLinks";
import HopeThatHelped from "../components/Blog/HopeThatHelped/HopeThatHelped";
import HaveAQuestion from "../components/Blog/HaveAQuestion/HaveAQuestion";
import Newsletter from "../components/Blog/Newsletter/Newsletter";
import RelatedArticles from "../components/Blog/RelatedArticles/RelatedArticles";
import CategoryArticles from "../components/Blog/CategoryArticles/CategoryArticles";
import CTA from "../components/Blog/CTA/CTA";

const useInlineParsingV2 = (content, url, description, articles) => {
  const parseJSXForInlineCTAS = (items, description) =>
    items.map((item) => {
      switch (item.type) {
        case "jsx-inline-image":
          const { alt, src, width } = item.content;
          return {
            type: "jsx",
            content: (
              <Imgix
                src={`${src}?auto=format`}
                width={width || "100%"}
                htmlAttributes={{
                  alt: alt || "Bidddy Image",
                  src: `${src}?blur=500&px=4&auto=format`,
                }}
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
                }}
                className="lazyload blur-up"
              />
            ),
          };
        case "jsx-inline-image-list":
          // Fix this

          // const srcs = item.content.src; // list of src fields
          return {
            type: "jsx",
            content: (
              <>
                <div />
              </>
              // <Imgix
              //   src={item.content.src}
              //   sizes="100vw"
              //   htmlAttributes={{
              //     alt: item.content.alt || 'Bidddy Image',
              //     src: `${item.content.src}?blur=500&px=4&auto=format`,
              //   }}
              //   attributeConfig={{
              //     src: 'data-src',
              //     srcSet: 'data-srcset',
              //     sizes: 'data-sizes',
              //   }}
              //   className="lazyload blur-up"
              // />
            ),
          };

        case "jsx-newsletter":
          return {
            type: "jsx",
            content: <Newsletter />,
          };
        case "jsx-inline-social":
          // Pass meta info through here
          const { text } = item.content;
          return {
            type: "jsx",
            content: (
              <SocialLinks shareURL={url} shareText={description || ''} text={text} />
            ),
          };
        case "jsx-dropdown":
          return {
            type: "jsx",
            content: (
              <Dropdown
                question={item.content.title}
                answer={item.content.content}
              />
            ),
          };
        case "jsx-linked-header":
          const { title, id } = item.content;
          return {
            type: "jsx",
            content: <TitleBlock title={title} id={id} />,
          };
        case "jsx-inline-question-form":
          return {
            type: "jsx",
            content: <HaveAQuestion />,
          };
        case "jsx-hope-that-helped":
          return {
            type: "jsx",
            content: <HopeThatHelped />,
          };
        case "jsx-related-articles":
          const { articles: Articles, listOfIds } = item.content;
          return {
            type: "jsx",
            content: <RelatedArticles articles={Articles} />,
          };
        case "jsx-inline-category-articles":
          const { categories, articles: categoryArticles } = item.content;
          return {
            type: "jsx",
            content: (
              <CategoryArticles
                categories={categories}
                articles={categoryArticles}
              />
            ),
          };
        case "jsx-inline-cta":
          const { title: ctaTitle, description: ctaDescription, button, buttonText } = item.content;
          return {
            type: "jsx",
            content: (
              <CTA title={ctaTitle} description={ctaDescription} button={button} buttonPath={buttonText} />
            )
          }
        // Note - In the future here is where we could return a parsing error into
        // the content if the env is dev. If it's prod we could just return an empty string
        default:
          return item;
      }
    });

  return parseJSXForInlineCTAS(content, description);
};

export default useInlineParsingV2;
