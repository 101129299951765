import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./CategoryArticles.module.scss";
import { Article } from "../RelatedArticles/RelatedArticles";
import TablePagination from "../../Pagination/TablePagination";

const ITEMS_PER_PAGE = 6;

const CategoryArticles = ({ articles, categories }) => {
  const filteredArticles = articles.filter((article) => article !== null);
  const [activeCategory, setActiveCategory] = useState("All");
  const [categoryList, setCategoryList] = useState({});
  const [categoryListNames, setCategoryListNames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    // Organize categories into an object
    const categoriesObj = {};
    const categoryNames = ["All"];
    categories.forEach((category) => {
      // lowercase category
      categoryNames.push(category.title);
      categoriesObj[category.title] = [];
    });
    // Assign articles to their respective categories
    categories.forEach((category) => {
      filteredArticles.forEach((article) => {
        article.metadata.categories.forEach((articleCategory) => {
          if (articleCategory.slug === category.slug) {
            categoriesObj[category.title].push(article);
          }
        });
      });
    });
    setCategoryList(categoriesObj);
    setCategoryListNames(categoryNames);
  }, [categories, articles]);

  const getCount = (categoryName) => {
    if (categoryName === "All") {
      return articles.length;
    }
    return categoryList[categoryName].length;
  };

  const selectCategory = (categoryName) => {
    setCurrentPage(1);
    setActiveCategory(categoryName);
  };

  const getCategoryCount = () => {
    if (activeCategory === "All") {
      return articles.length ? Math.ceil(articles.length / ITEMS_PER_PAGE) : 0;
    }
    return categoryList[activeCategory]
      ? Math.ceil(categoryList[activeCategory].length / ITEMS_PER_PAGE)
      : 0;
  };

  const setNewPage = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className={styles.categoryArticlesContainer}>
      <div className={styles.categoriesList}>
        {categoryListNames.map((categoryName) => (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => selectCategory(categoryName)}
            key={`category-${categoryName}`}
            onClick={() => selectCategory(categoryName)}
            className={cn({
              [styles.selectedCategory]: activeCategory === categoryName,
            })}
          >
            {categoryName} ({getCount(categoryName)})
          </div>
        ))}
      </div>
      <div className={styles.articleCategoryList}>
        {categoryList[activeCategory] &&
          categoryList[activeCategory]
            .slice(
              (currentPage - 1) * ITEMS_PER_PAGE,
              currentPage * ITEMS_PER_PAGE
            )
            .map((article) => (
              <Article key={`${activeCategory}-${article}`} article={article} />
            ))}
        {activeCategory === "All" &&
          filteredArticles
            .slice(
              (currentPage - 1) * ITEMS_PER_PAGE,
              currentPage * ITEMS_PER_PAGE
            )
            .map((article) => (
              <Article
                key={`${article.slug}-${activeCategory}`}
                article={article}
              />
            ))}
      </div>
      <TablePagination
        count={getCategoryCount()}
        currentPage={currentPage}
        setCurrentPage={(newPage) => setNewPage(newPage)}
      />
    </div>
  );
};

export default CategoryArticles;
