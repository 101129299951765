import React from "react";
import cn from "classnames";
import styles from "./DefaultInput.module.scss";

const DefaultInput = (props) => {
  const { className } = props;
  return (
    <>
      <input {...props} className={cn([className, styles.defaultInput])} />
    </>
  );
};

export default DefaultInput;
