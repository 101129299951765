import { Link } from "gatsby";
import React from "react";
import Imgix from "react-imgix";
import styles from "./HopeThatHelped.module.scss";

const BidddyGuy =
  "https://imgix.cosmicjs.com/2702a190-febd-11eb-aaf9-c7d10ae67fc2-ThumbsUpBidFoot.png";
const HopeThatHelped = () => (
  <div className={styles.hopeThatHelpedContainer}>
    <Imgix
      src={`${BidddyGuy}?auto=format`}
      width={100}
      htmlAttributes={{
        alt: "Hope That Helped",
        src: `${BidddyGuy}?blur=500&px=4&auto=format`,
      }}
      attributeConfig={{
        src: "data-src",
        srcSet: "data-srcset",
        sizes: "data-sizes",
      }}
      className="lazyload blur-up"
    />
    <h5>We hope that helped!</h5>
    <p>Looking for more helpful tips, please visit our Learn Section.</p>
    <Link to="/learn/">Click here.</Link>
  </div>
);

export default HopeThatHelped;
