import React from "react";
import Helmet from "react-helmet";
import styles from "../../styles/NewBlog.module.scss";

const isProduction = process.env.GATSBY_PUBLIC_ENV === "production";

const SEO = ({ metaContext }) => (
  <Helmet>
    <html lang="en" className={styles.htmlContainer} />
    <title>{metaContext.title}</title>
    <link rel="canonical" href={metaContext.canonical} />
    <meta property="og:url" content={metaContext.canonical} />
    <meta name="site_name" property="og:site_name" content="Bidddy" />
    <meta property="og:title" content={metaContext.title} />
    <meta property="og:description" content={metaContext.description} />
    <meta name="description" content={metaContext.description} />
    <meta charSet="utf-8" />
    <meta property="article:published_time" content={metaContext.modified_at} />
    {metaContext.image && metaContext.image.imgix_url && (
      <meta property="og:image" content={metaContext.image.imgix_url} />
    )}
    {metaContext.image && metaContext.image.imgix_url && (
      <meta
        property="og:image:secure_url"
        content={metaContext.image.imgix_url}
      />
    )}
    <meta property="og:image:width" content="1360" />
    <meta property="og:image:height" content="680" />
    <meta property="og:image:alt" content={metaContext.title} />
    {/* TODO: add no index {noIndex && <meta name="robots" content="noindex" />} */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="article:publisher"
      content="https://www.facebook.com/getbidddy/"
    />
    <meta name="twitter:creator" content="Bidddy" />
    {metaContext.image && metaContext.image.imgix_url && (
      <meta name="twitter:image" content={metaContext.image.imgix_url} />
    )}
    <meta name="twitter:title" content={metaContext.title} />
    <meta name="twitter:description" content={metaContext.description} />
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    <link rel="preload" as="style" href="https://use.typekit.net/bal2stb.css" />
    <link rel="stylesheet" href="https://use.typekit.net/bal2stb.css" />
    {isProduction && (
      <>
        <script
          defer
          src="https://www.googletagmanager.com/gtag/js?id=UA-181709414-1"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-181709414-1', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </>
    )}
    {metaContext.structured_data && (
      <script type="application/ld+json">
        {JSON.stringify(metaContext.structured_data)}
      </script>
    )}
  </Helmet>
);

export default SEO;
