import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from "./BidddyDropdown.module.scss";

const BidddyDropdown = ({
  question,
  answer,
  hasTopBorder = false,
  hasBottomBorder = true,
}) => {
  const [opened, setOpened] = useState(false);
  const [height, setHeight] = useState(45);
  const questionRef = useRef(null);

  useEffect(() => {
    if (questionRef && questionRef.current) {
      if (questionRef.current.clientHeight) {
        setHeight((questionRef.current.clientHeight as number) + 5);
      }
    }
  }, [questionRef]);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => setOpened(!opened)}
      style={{
        maxHeight: opened ? "100%" : height,
      }}
      onClick={() => setOpened(!opened)}
      className={cn([
        styles.bidddyDropdownContainer,
        {
          [styles.opened]: opened,
          [styles.hasBottomBorder]: hasBottomBorder,
          [styles.hasTopBorder]: hasTopBorder,
        },
      ])}
    >
      <h5 ref={questionRef} id={question.replace(/\s+/g, "").toLowerCase()}>
        {question}
      </h5>
      <div dangerouslySetInnerHTML={{ __html: answer }} />
    </div>
  );
};

export default BidddyDropdown;
